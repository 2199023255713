/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:188d2942-4179-4d87-854f-4c57520ea46c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_RMVAAl6u4",
    "aws_user_pools_web_client_id": "23b1mv0pk0fb1p53i3jcg91pe7",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://avjsk75nyjdbdgvzscxuiqizlm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-sqwpmqdhtjgobe4uyp574ytzw4",
    "aws_user_files_s3_bucket": "amplifyapp089ae31305d44e6fbae2e5380aed6cce124322-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
